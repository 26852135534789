export default {
    "Login": "Log in",
    "Logout": "Uitloggen",
    "Account": "Account",
    "Home": "Home",
    "Invoices": "Facturen",
    "Invoice requests": "Factuurverzoeken",
    "Billing address": "Factuuradres",
    "Billing addresses": "Factuuradressen",
    "Add": "Toevoegen",
    "Username": "Gebruikersnaam",
    "Are you sure to delete this address?": "Weet je zeker dat je dit adres wilt verwijderen?",
    "Address details": "Adresgegevens",
    "Address updated successfully.": "Adres succesvol bijgewerkt.",
    "Create a new address": "Een nieuw adres maken",
    "Address created successfully.": "Adres succesvol aangemaakt.",
    "Address list": "Adreslijst",
    "Create": "Creëren",
    "Civility / Type": "Beleefdheidsvorm / Type",
    "Name": "Naam",
    "Address": "Adres",
    "No addresses found": "Geen adressen gevonden",
    "Invoice Request": "Factuurverzoek",
    "2. Add your receipts": "2. Voeg uw bonnen toe",
    "Next": "Volgende",
    "Request an invoice": "Vraag een factuur aan",
    "4. Confirm": "4. Bevestig",
    "Please double check your details to make sure they are correct.": "Controleer uw gegevens nogmaals om er zeker van te zijn dat ze correct zijn.",
    "The creation of an invoice is binding and cannot be undone.": "Het aanmaken van een factuur is bindend en kan niet ongedaan worden gemaakt.",
    "None": "Geen",
    "Back to this step": "Terug naar deze stap",
    "Allocate the invoice to the current month.": "Wijs de factuur toe aan de huidige maand.",
    "Allocate the invoice to the previous month.": "Wijs de factuur toe aan de vorige maand.",
    "Send me an email as soon as the status of my request changes.": "Stuur mij een e-mail zodra de status van mijn verzoek verandert.",
    "Send the invoice request": "Stuur het factuurverzoek",
    "5. Finished": "5. Klaar",
    "Invoice requested successfully.": "Factuur correct aangevraagd.",
    "Invoice request list": "Factuuraanvraaglijst",
    "3. Options": "3. Opties",
    "Month allocation": "Maandtoewijzing",
    "Current month": "Deze maand",
    "Previous month": "Vorige maand",
    "The invoice can be allocated to the current or previous month. The date of the invoice is set depending on this choice.": "De factuur kan worden toegewezen aan de huidige of aan de vorige maand. Afhankelijk van deze keuze wordt de datum van de factuur ingesteld.",
    "The date of the invoice will be today.": "De datum van de factuur is vandaag.",
    "The date of the invoice will be the last day of the previous month. This feature is available until the 5th day of the current month.": "De datum van de factuur is de laatste dag van de vorige maand. Deze functie is beschikbaar tot de 5e dag van de huidige maand.",
    "Notify me": "Informeer mij",
    "E-mail": "E-mail",
    "Send me a notification as soon as the status of my request changes.": "Stuur mij een melding zodra de status van mijn verzoek verandert.",
    "1. Select an address": "1. Selecteer een adres",
    "New address": "Nieuw adres",
    "Save address": "Adres opslaan",
    "The data entered will not be saved and is only valid for this request.": "De ingevoerde gegevens worden niet opgeslagen en zijn alleen geldig voor dit verzoek.",
    "You can save this address and thus reuse it for further invoices.": "U kunt dit adres opslaan en zo hergebruiken voor verdere facturen.",
    "Invoice Request Details": "Factuurverzoekgegevens",
    "Date": "Datum",
    "Status": "Status",
    "Invoice Request List": "Factuuraanvraaglijst",
    "Number of receipts": "Aantal bonnen",
    "Failed": "Mislukt",
    "No invoice requests found": "Geen factuurverzoeken gevonden",
    "Downloading invoice": "Factuur wordt gedownload",
    "If your download does not start, please click here:": "Als uw download niet begint, klik dan hier:",
    "Invoice List": "Factuurlijst",
    "My Invoices": "Mijn facturen",
    "Invoices of my sites": "Facturen van mijn sites",
    "Page not found.": "Pagina niet gevonden.",
    "TotalEnergies Invoicing Platform": "TotalEnergies facturatieplatform",
    "Preparing": "Voorbereidingen treffen",
    "Your request has been submitted successfully and will be processed shortly.": "Uw aanvraag is met succes ingediend en zal binnenkort worden verwerkt.",
    "Processing": "Verwerken",
    "Your request is currently being processed.": "Uw verzoek wordt momenteel verwerkt.",
    "Finished": "Klaar",
    "Your request has been processed successfully.": "Uw aanvraag is correct verwerkt.",
    "Unfortunately, your request could not be processed successfully.": "Helaas kon uw verzoek niet correct worden verwerkt.",
    "VAT number is required": "BTW-nummer is verplicht",
    "Type is required": "Type is verplicht",
    "Name is required": "Naam is verplicht",
    "Civility is required": "Aanspreekvorm is verplicht",
    "First name is required": "Voornaam is verplicht",
    "Last name is required": "Achternaam is verplicht",
    "Address is required": "Adres is verplicht",
    "Zip/Postal code is required": "Postcode is verplicht",
    "City is required": "Plaats is verplicht",
    "Country is required": "Land is verplicht",
    "E-mail is required": "E-mail is verplicht",
    "E-mail is invalid": "E-mail is ongeldig",
    "Company": "Bedrijf",
    "Private": "Particulier",
    "Civility": "Aanspreekvorm",
    "Type": "Type",
    "Please select": "Selecteer alstublieft",
    "customer_title_0": "Ander",
    "customer_title_1": "Dhr.",
    "customer_title_2": "Mevr.",
    "customer_title_3": "Mej.",
    "customer_title_4": "NV",
    "customer_title_5": "BV",
    "customer_title_6": "VZW",
    "customer_title_7": "BVBA",
    "customer_title_8": "CVBA",
    "customer_title_9": "EV",
    "customer_title_10": ".",
    "customer_title_11": "Dhr. Mevr.",
    "customer_title_12": "Indép.",
    "customer_title_13": "EURL",
    "customer_title_14": "SAS",
    "customer_title_15": "VOF",
    "customer_title_16": "Comm.V./GCV",
    "customer_title_17": "Comm.V.A.",
    "First name": "Voornaam",
    "Last name": "Achternaam",
    "Company type": "Bedrijfstype",
    "Company name": "Bedrijfsnaam",
    "VAT": "BTW",
    "VAT number": "BTW-nummer",
    "Starts with an country code (usually 2 letters) followed by up to 13 characters, e.g. BE09999999XX, FRXX999999999, NL999999999B01": "Begint met een landcode (meestal 2 letters) gevolgd door maximaal 13 tekens, b.v. BE09999999XX, FRXX999999999, NL999999999B01",
    "Street address": "Adres",
    "Zip/Postal code": "Postcode",
    "City": "Plaats",
    "Country": "Land",
    "Free text": "Vrije tekst",
    "No invoices found": "Geen facturen gevonden",
    "ID": "ID",
    "File": "Het dossier",
    "Receipts": "Ontvangsten",
    "Documents": "Documenten",
    "Petrol station": "Benzinestation",
    "Receipt number": "Ticketnummer",
    "Amount (Incl. Tax)": "Bedrag (BTW incl.)",
    "Options": "Opties",
    "Assignments": "Opdrachten",
    "Administration": "Administratie",
    "Search by number, name or address": "Zoek op nummer, naam of adres",
    "If the station does not appear in this list, please contact directly the manager of the station where the purchase was made.": "Als het station niet in deze lijst voorkomt, neem dan rechtstreeks contact op met de beheerder van het station waar de aankoop is gedaan.",
    "The invoice cannot be assigned to the previous month. Because it has a receipt for the current month.": "De factuur kan niet worden toegewezen aan de vorige maand. Omdat het een bon heeft voor de huidige maand.",
    "If you still need an invoice for the previous month. Go back one step and delete the corresponding receipt.":"Als u nog een factuur nodig heeft van de vorige maand. Ga een stap terug en verwijder de bijbehorende bon.",
     
    //Receipt Status
    "difference in the receipt total amount": "verschil in het totale bedrag van de kassabon",
    "unknown paymode": "onbekende betaalmodus",
    "receipt already invoiced": "ontvangst al gefactureerd",
    "receipt paid with a company card": "ontvangstbewijs betaald met een bedrijfskaart",
    "receipt not found": "bon niet gevonden",
    "ok" : "ok",

    "France" : "Frankrijk",
    "Belgium" : "België",
    "Germany" : "Duitsland",
    "Luxembourg" : "Luxemburg",
    "The Netherlands" : "Nederland",
    "Spain" : "Spanje",
    "Portugal" : "Portugal",
    "Italy" : "Italië",
    "The United Kingdom" : "Groot-Brittannië",
    "Switzerland" : "Zwitserland",
    "Denmark" : "Denemarken",
    "Austria" : "Oostenrijk",
    "Bulgaria" : "Bulgarije",
    "Finland" : "Finland",
    "Hungary" : "Hongarije",
    "Poland" : "Polen",
    "Romania" : "Roemenië",
    "Slovakia" : "Slovakije",
    "Slovenia" : "Slovenië",
    "Sweden" : "Zweden",
    "Estonia" : "Estland",
    "Latvia" : "Letland",
    "Lithuania" : "Litouwen",
    "Czech Republic" : "Tsjechische Republiek",
    "Croatia" : "Kroatië",
    "Greece" : "Griekenland",
    "Macedonia" : "Macedonië",
    "Cameroon" : "Kameroen",
    "Lebanon" : "Libanon",
    "Equatorial Guinea" : "Equatoriaal-Guinea",
    "Tunisia" : "Tunesië",
    "Northern Ireland" : "Northern Ireland",
    "Fidji" : "Fidji",
    "Egypt" : "Egypte",
    "Mauritius" : "Mauritius",
    "Uganda" : "Oeganda",
    "Ireland" : "Ireland",
    "Vanuatu" : "Vanuatu",
    "Congo" : "Congo",
    "French Polynesia" : "Frans-Polynesië",
    "Morocco" : "Marokko",
    "Tanzania" : "Tanzania",
    "Nigeria" : "Nigeria",
    "Gabon" : "Gabon",
    "Tchad" : "Tchad",
    "Kenya" : "Kenya",
    "Ghana" : "Ghana",
    "Malawi" : "Malawi",
    "Niger" : "Niger",
    "Togo" : "Togo *",
    "Côte d'Ivoire" : "Côte d'Ivoire",
    "Mali" : "Mali",
    "Sierra Leone" : "Sierra Leone",
    "Mozambique" : "Mozambique",
    "Centrafrique" : "Centrafrique",
    "Senegal" : "Senegal",
    "Burkina Faso" : "Burkina Faso",
    "République Démocratique du Congo" : "République Démocratique du Congo",
    "Guinée Conakry" : "Guinée Conakry",
    "Zimbabwe" : "Zimbabwe",
    "Australia" : "Australia",
    "Honduras" : "Honduras",
    "Nouvelle-Calédonie" : "Nouvelle-Calédonie",
    "Kenya" : "Kenya",
    "Papouasie Nouvelle Guinée" : "Papouasie Nouvelle Guinée",
    "Zambia" : "Zambia",
    "Madagascar" : "Madagascar",
    "Liberia" : "LiberiaGuadeloupe",
    "GuadeloupeKingdom of Saudi Arabia" : "Kingdom of Saudi Arabia",
    "Serbia" : "Serbia",
    "Montenegro" : "Montenegro",
    "Kosovo" : "Kosovo",
    "Bosnia and Herzegovina" : "Bosnia and Herzegovina",
    "Barbados" : "Barbados",
    "Angola" : "Angola",

    // TicketHelperModal
    "Help": "Hulp",
    "Till receipt": "Kassa ticket",
    "Forecourt receipt": "Ticket piste",
    "Close": "Sluiten",

    // Home
    "Dear Customer,": "Beste Klant,",
    "TotalEnergies Belgium now offers you, through this site, a unique tool for editing and receiving in a personalized way your invoices which are linked to your purchases made at our service stations.": "TotalEnergies Belgium biedt u via deze site een unieke tool aan om uw facturen voor uw aankopen in onze tankstations zelf op te maken en te ontvangen.",
    "To edit and receive an invoice corresponding to your purchases made in stations, all you need to do is bring your ticket: you will be asked to provide the number, amount, date and name of the station. These references allow us to retrieve the data that will be the subject of an invoice to you. The procedure for editing your invoice is made simple and intuitive, let yourself be guided!": "Om een factuur voor uw aankopen in onze tankstations op te maken en te ontvangen, volstaat het om de volgende gegevens van uw ticket in te geven: nummer van het ticket, bedrag, datum en de naam van het station. Deze referenties laten ons toe om de gegevens op te halen om uw factuur op te maken. Het proces voor het opmaken van uw factuur is simpel en intuïtief, laat u gewoon leiden!",
    "If, however, the station in which you made your purchases was not in the list of stations offered, we invite you to contact the manager of this station directly, who will be happy to produce an invoice for you that corresponds to your purchases.": "Indien het tankstation waar u uw aankopen heeft gedaan toch niet in de lijst van de voorgestelde stations staat, vragen wij u om contact op te nemen met de uitbater van het station, die u met plezier een factuur voor uw aankopen zal opmaken.",
    "Finally, if you want to know the absolute ease of billing, we suggest you opt for a Total fuel card. To do this, simply click on the link below:": "Indien u tenslotte het absolute gemak wat facturatie betreft zoekt, raden wij u aan om een Total tankkaart aan te vragen. Hiervoor volstaat het om op onderstaande link te klikken:",
    "link_opt_total_card_href": "https://services.totalenergies.be/nl/professionelen/totalenergies-cards",
    "link_opt_total_card_label": "TotalEnergies cards | TotalEnergies België",
    "TotalEnergies Belgium and your station thank you for your trust.": "TotalEnergies Belgium en uw tankstation dankt u voor uw vertrouwen.",

    // Help
    "For any question relating to the use of this site, you can send an email to": "Voor elke vraag met betrekking tot het gebruik van deze site kunt u een e-mail sturen naar",

    // Footer
    "Terms of service": "Algemene Gebruiksvoorwaarden",
    "Personal data & cookies": "Persoonlijke gegevens & cookies",
    "Sitemap": "Sitemap",
    "General conditions of sale": "Algemene verkoopvoorwaarden",
    "All our sites": "Al onze sites",
    "Follow us": "Volg ons",
    "Follow us on Facebook (new window)": "Volg ons op Facebook (nieuw venster)",
    "Follow us on Twitter (new window)": "Volg ons op Twitter (nieuw venster)",
    "Follow us on LinkedIn (new window)": "Volg ons op LinkedIn (nieuw venster)",
    "Follow us on YouTube (new window)": "Volg ons op YouTube (nieuw venster)",
    "Follow us on Instagram (new window)": "Volg ons op Instagram (nieuw venster)",

    // info notification
    "Dear Customer,": "Beste Klant,",
    "Due to a technical issue, we are currently unable to process invoices for transactions made after November 17th. Our teams are working hard to resolve this issue as soon as possible. We kindly ask you to keep all proof of your transactions during this period.": "Vanwege een technisch probleem zijn we momenteel niet in staat om facturen te verwerken voor transacties die na 17 november zijn uitgevoerd. Onze teams werken hard om dit probleem zo snel mogelijk op te lossen. Wij verzoeken u vriendelijk alle bewijzen van uw transacties te bewaren gedurende deze periode.",
}
